<template>
   <section class="cont userCheck-box">
   <!-- 面包屑 /user_check -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>SCRM</el-breadcrumb-item>
            <el-breadcrumb-item  :to="{path:'/member_user/user_list'}" v-if="memberInfo == 'user_list' ">
               用户列表
            </el-breadcrumb-item>
            <el-breadcrumb-item v-else  :to="{path:'/member_user/member_management'}">
               会员管理
            </el-breadcrumb-item>
            <el-breadcrumb-item>详情</el-breadcrumb-item>
         </el-breadcrumb>
         <el-button class="bg-gradient" style="float: right" @click="goBack">返回</el-button>
      </el-row>

      <el-row class="content-box" v-if="onememberInfo != '' ">
         <ul class="ul-box">
            <li>
               <div class="title">基础信息</div>
               <el-row class="row-box">
                  <el-col :span="12" class="p-left-40">
                     <el-row><span>ID：</span> {{onememberInfo.id || '' }}；</el-row>
                     <el-row><span>姓名：</span> {{onememberInfo.realName }}；</el-row>
                     <el-row><span>证件类型：</span> {{ onememberInfo.certificateType | filtercertificateSource }}；</el-row>
                     <el-row><span>证件号码：</span> {{onememberInfo.certificateNo }}；</el-row>
                     <el-row v-if="onememberInfo.province.indexOf(',') !=-1 ">
                        <span >地址：</span> {{ onememberInfo.province.substr(7,10)}}{{onememberInfo.city.substr(7,10)}}{{onememberInfo.district}}{{onememberInfo.address.substr(7,10)}}；
                     </el-row>
                     <el-row v-else>
                          <span>地址：</span> {{ onememberInfo.province}}{{onememberInfo.city}}{{onememberInfo.district}}{{onememberInfo.address}}；
                     </el-row>
                  </el-col>
                  <el-col :span="12" class="p-left-40">
                     <el-row><span>手机号：</span> {{onememberInfo.mobile }}；</el-row>
                     <el-row><span>来源：</span> {{onememberInfo.userSource | filterUserSource}}；</el-row>
                     <el-row><span>行业：</span> {{ }}；</el-row>
                     <el-row><span>备注：</span> {{onememberInfo.remark }}；</el-row>
                     <el-row><span>储值卡：</span> {{ onememberInfo.amount}}；</el-row>
                  </el-col>
               </el-row>
            </li>
            <li>
               <div class="title">新媒体信息</div>
               <el-row class="row-box">
                  <el-col :span="24" class="p-left-40">
                     <el-tabs v-model="new_tabState" @tab-click="handleNew" class="new-tabbox">
                        <el-tab-pane label="微信公众号" name="WECHAT_APPLET">
                           <el-row style="position: relative" >
                              <span>头像：</span>
                              <img v-if="onememberInfo.headPortrait" style="position: relative;top: 10px;left: 10px;height: 35px;width: 35px;border-radius: 50%" :src="onememberInfo.headPortrait" alt="">
                              <img v-else style="height: 35px;width: 35px;border-radius: 50%" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" alt="">
                           </el-row>
                           <el-row><span>昵称：</span> <label>{{onememberInfo.nickName || ''}}</label> </el-row>
                           <el-row><span>oppoid：</span> <label v-if="this.selfinfo != '' ">{{this.selfinfo.openId || ''}}</label> </el-row>
                           <el-row><span>关注时间：</span> <label v-if="this.selfinfo != '' ">{{this.selfinfo.createTime || ''}}</label> </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="抖音" name="DOUYIN_PLATFORM">
                           <el-row style="position: relative">
                              <span>头像：</span>
                              <img v-if="onememberInfo.headPortrait" style="position: relative;top: 10px;left: 10px;height: 35px;width: 35px;border-radius: 50%" :src="onememberInfo.headPortrait" alt="">
                              <img v-else style="position: relative;top: 10px;left: 10px;height: 35px;width: 35px;border-radius: 50%" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" alt="">
                           </el-row>
                           <el-row><span>昵称：</span> <label v-if="this.selfinfo != '' ">{{onememberInfo.nickName || ''}}</label> </el-row>
                           <el-row><span>oppoid：</span><label v-if="this.selfinfo != '' ">{{this.selfinfo.openId || ''}}</label></el-row>
                           <el-row><span>关注时间：</span><label v-if="this.selfinfo != '' ">{{this.selfinfo.createTime || ''}}</label></el-row>
                        </el-tab-pane>
                     </el-tabs>
                  </el-col>
               </el-row>
            </li>
            <li>
               <div class="title">{{action_tabState}}</div>
               <el-row class="action-news">
                     <el-tabs v-model="action_tabState" @tab-click="getmemberAction(action_tabState)" class="action-tabbox">
                        <el-tab-pane label="行为轨迹" name="行为轨迹">
                           <el-table
                                 :data="tracktableData"
                                 border fit
                                 height="500px"
                                 style="width:100%;overflow-y: auto;"
                                 :stripe="true"
                                 :header-cell-style="{background: '#f5f8f9'}">
                              <el-table-column prop="medalName" label="行为" >
                                 <template slot-scope="scope">
                                    {{ scope.row.source | filtertrack}}
                                 </template>
                              </el-table-column>
                              <el-table-column label="时间" prop="operateTime" />
                           </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="营销跟踪" name="营销跟踪">
                           <el-table
                                 :data="tracktableData"
                                 border fit
                                 height="500px"
                                 style="width:100%;overflow-y: auto;"
                                 :stripe="true"
                                 :header-cell-style="{background: '#f5f8f9'}">
                              <el-table-column prop="mainTitle" label="营销类型" />
                              <el-table-column prop="buyingTime" label="时间" />
                              <el-table-column label="状态"  >
                                 <template slot-scope="scope">
                                    <span v-if="scope.row.hasOwnProperty('coverPath')">{{scope.row.state | circlingFilter(that)}}</span>
                                    <span v-else>{{scope.row.state | discountsFilter(that)}}</span>

                                 </template>
                              </el-table-column>
                           </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="消费跟踪" name="消费跟踪">
                           <el-table
                                 :data="tracktableData"
                                 border fit
                                 height="500px"
                                 style="width:100%;overflow-y: auto;"
                                 :stripe="true"
                                 :header-cell-style="{background: '#f5f8f9'}">
                           <el-table-column key="1" prop="orderNo" label="订单编号" />
                              <el-table-column key="2" label="成交时间" prop="payTime" >
                                 <template slot-scope="scope">
                                    {{scope.row.payTime || scope.row.updateTime}}
                                 </template>
                              </el-table-column>
                           <el-table-column key="3" label="订单金额" prop="orderTotalPrice" />
                           <el-table-column key="4" label="实际支付" prop="actualAmount" />
                           <el-table-column key="5" label="支付方式" prop="paymentMethod" >
                              <template slot-scope="scope">
                                 {{scope.row.paymentMethod | payFilter(that)}}
                              </template>
                           </el-table-column>
                           <el-table-column key="6" label="储值卡余额" >
                              <template slot-scope="scope">
                                 {{scope.row.balance || '-'}}
                              </template>
                           </el-table-column>
                        </el-table>
                        </el-tab-pane>

                        <el-tab-pane label="充值记录" name="充值记录">
                           <el-table
                                 :data="tracktableData"
                                 border fit
                                 height="500px"
                                 style="width:100%;overflow-y: auto;"
                                 :stripe="true"
                                 :header-cell-style="{background: '#f5f8f9'}">
                              <el-table-column key="1" prop="createTime" label="充值时间" />
                              <el-table-column key="2" label="充值金额" prop="payAmount" />
                              <el-table-column key="3" label="赠送金额" prop="donateAmount" />
                              <el-table-column key="4" label="余额" prop="balance" />
                           </el-table>
                        </el-tab-pane>

                        <!-- 分页 -->
                        <el-pagination style="float: right;" small layout="prev, pager, next" :total="total" :page-size="limit" :current-page="page"
                                       @prev-click="handlePrev" @next-click="handleNext">
                        </el-pagination>
                     </el-tabs>
               </el-row>
            </li>
         </ul>
      </el-row>
   </section>
</template>

<script>
import {urlObj} from "@/api/interface";
import {mapState} from "vuex";
import {getDict} from "@/common/js/common";

export default {
   data(){
     return{
        that:this,
        memberInfo:'',
        selfinfo:'',//多媒体信息
        new_tabState:'WECHAT_APPLET',     //新媒体tab状态
        action_tabState:"行为轨迹",  //行为tab状态
        onememberInfo:'',    //会员详情
        loading:true,       // 表格加载效果
        tracktableData:[],
        param:'',
        url:'',
        tableData:[],        // 表格数据
        total: 0,            // 用户列表总条目数
        page: 1,             // 当前页 默认第一页
        limit: 10,            // 每页显示数
     }
   },

   computed: {
      ...mapState(['hotelInfo','dictData'])
   },
   filters:{
      payFilter(val, that){
         if(val === 'CASH') return '现金'
         return that.dictData['payment-method'][val] || val
      },
      circlingFilter(val, that){
         return that.dictData['preSale-state'][val] || val
      },
      discountsFilter(val, that){
         return that.dictData['coupon-state'][val] || val
      },
      filtertrack(val){
         switch (val) {
            case 'WECHAT_APPLET':
               return val = '进入微信小程序'
            case 'DOUYIN_APPLET':
               return val = '进入抖音小程序'
            case 'ORTHER':
               return val = '创建用户'
            case 'WECHAT_OFFICIAL':
               return val = '关注微信公众号'
         }
      },
      filtercertificateSource(val){
         switch (val) {
            case 'IDCARD':
               return val = '身份证'
            case 'PASSPORT':
               return val = '护照'
            case 'OTHER':
               return val = '其他'
         }
      },
      filterUserSource(val){
         switch (val) {
            case 'APPLETS':
               return val = '小程序'
            case 'SSM':
               return val = '自助机'
            case 'APP':
               return val = 'APP'
            case 'WEB':
               return val = 'PC端'
            case 'PLATFORM':
               return val = '平台'
            case 'SMS':
               return val = '短信'
            case 'EMAIL':
               return val = '邮件'
            case 'WECHAT_OFFICIAL':
               return val = '微信公众号'
            case 'WECHAT_APPLET':
               return val = '微信小程序'
            case 'QQ':
               return val = 'QQ'
            case 'ALIPAY':
               return val = '支付宝'
            case 'DINGTALK':
               return val = '钉钉'
            case 'DOUYIN_PLATFORM':
               return val = '抖音'
            case 'XIAOHONGSHU':
               return val = '小红书'
            case 'PMS':
               return val = '酒店PMS'
         }
      }
   },
   created() {
      this.onememberInfo = JSON.parse(sessionStorage.getItem('onememberInfo'))
      this.memberInfo = this.$route.query.memberInfo
      getDict(["certificate-type",'order-status','payment-method','preSale-state','coupon-state'])
      this.handleNew()
   },
   mounted() {
      this.getmemberAction('行为轨迹')


   },
   destroyed() {
      sessionStorage.removeItem("onememberInfo");
   },
   methods:{
      //新媒体信息tabs切换
      handleNew(tab, event) {
         const url = urlObj.getPlatformListinfo
         let param = {
            memberId:this.onememberInfo.id,
            hotelId:this.hotelInfo.id,
            source:this.new_tabState
         }
         this.$axios.post(url, param,'json').then(res => {
            if (res.success) {
               if (res.records.length != 0){
                  this.selfinfo = res.records[0]

               }

            }
         })

      },

      //获取轨迹
      getmemberAction(type){
        const hotelId = this.onememberInfo.hotelId || this.hotelInfo.id
         if (type == '行为轨迹'){
            this.url = urlObj.memberAction+`?limit=${this.limit}&page=${this.page}`
            this.param = {
               memberId:this.onememberInfo.id,
               hotelId:hotelId
            }
         }
         if (type == '营销跟踪'){
            this.url = urlObj.getCouponListByUserId+`?limit=${this.limit}&page=${this.page}`
            this.param = {
               userId:this.onememberInfo.id,
               hotelId:hotelId
            }
            this.$axios.post(this.url, this.param).then(res => {
               if (res.success) {
                  this.loading = false
                  this.tracktableData = res.records
                  this.total = res.tracktotal
                  this.tracktype = type
                  this.trackdialog = true
               }
            })
            return
         }
         if (type == '消费跟踪'){
            this.url = urlObj.getconsumeList+`?limit=${this.limit}&page=${this.page}`
            this.param = {
               userId:this.onememberInfo.id,
               hotelId:hotelId,
               /*
             已支付 HAVE_PAID
             确认 CONFIRM
             已入住 CHECKIN
             已退款 REFUNDED
             待发货 TO_BE_SHIPPED
             已退货 HAVE_TO_RETURN
             待收货 PENDING_RECEIPT
             交易成功 SUCCESSFUL_TRADE
             待评价 STAY_EVALUATE
             已评价 ALREADY_EVALUATE */
               orderStatusList:["HAVE_PAID","CONFIRM",'CHECKIN','REFUNDED','TO_BE_SHIPPED','HAVE_TO_RETURN',
                  'PENDING_RECEIPT','SUCCESSFUL_TRADE','STAY_EVALUATE','ALREADY_EVALUATE'],
            }
         }
         if (type == '充值记录'){
            this.url = urlObj.getdepositCardPay+`?limit=${this.limit}&page=${this.page}`
            this.param = {
               hotelId:hotelId,
               memberId:this.onememberInfo.id
            }
            this.$axios.get(this.url, this.param).then(res => {
               if (res.success) {
                  this.loading = false
                  this.tracktableData = res.records
                  this.total = res.tracktotal
                  this.tracktype = type
                  this.trackdialog = true
               }
            })
            return
         }
         this.$axios.post(this.url, this.param,'json').then(res => {
            if (res.success) {
               this.loading = false
               this.tracktableData = res.records
               this.total = res.tracktotal
               this.tracktype = type
               this.trackdialog = true
            }
         })
      },

      //返回上一页
      goBack(){
         this.$router.go(-1)
      },

      // 上一页
      handlePrev(num) {
         this.page = num
         this.getList()
      },
      // 下一页
      handleNext(num) {
         this.page = num
         this.getList()
      },
   },
}
</script>

<style scoped lang="scss">
.userCheck-box{
   .ul-box{
      list-style: none;
      li{
         box-shadow: 0px 1px 5px 1px rgba(146, 191, 255, 0.32);
         margin-bottom: 30px;
         .title{
            height: 50px;
            line-height: 50px;
            background: #f2f2f2;
            //text-indent: 10px;
            padding-left: 10px ;
         }
         .el-row{
            padding: 10px 0px;
         }
         .row-box{
            .el-col{
               span{display: inline-block;width: 80px;height: 30px;line-height: 30px;text-align: right}
            }
            .el-col:nth-child(1){
               border-right: 1px solid #d7d7d7;
            }
         }
         .action-news{
            width: 80%;
            margin: 0 40px;
            .el-col{
               padding: 0px 40px;
            }
         }
      }
   }
   ::v-deep .el-tabs__nav-wrap{
      margin: 0 0 31px;
      border-bottom: 1px solid #E3E4E5;
      .el-tabs__item{
         font-size: 18px;
         font-family: Microsoft YaHei;
         font-weight: 400;
         color: #2D2D2D;
      }
      .is-active{
         font-size: 18px;
         font-weight: bold;
         color: #2577E3;
      }
   }
    .new-tabbox{
       ::v-deep .el-tabs__nav-wrap{width: 167px;}
   }
   //.action-tabbox{
   //   ::v-deep .el-tabs__nav-wrap{width: 450px;}
   //}
}
</style>
